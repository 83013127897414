import BlankLayout from '@admin/components/BlankLayout'

const routes = [
  {
    path: '/classify',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '商品分类',
        path: '/classify'
      }
    },
    children: [
      {
        path: '',
        component: () => import('@admin/views/classify/Index')
      },
      {
        path: 'create',
        component: () => import('@admin/views/classify/Create'),
        meta: {
          breadcrumb: {
            title: '新建'
          }
        }
      },
      {
        path: 'update',
        component: () => import('@admin/views/classify/Edit'),
        meta: {
          breadcrumb: {
            title: '编辑'
          }
        }
      }
    ]
  }
]

export default routes
