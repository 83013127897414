<template>
  <div id="app">
    <login-modal v-if="loginModalVisible" :visible="true"></login-modal>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import LoginModal from '@admin/components/LoginModal'

export default {
  name: 'App',
  spinnerTimeoutId: null,
  components: { LoginModal },
  computed: {
    loginModalVisible () {
      return this.$store.getters['auth/isLoginModalVisible']
    }
  },
  beforeCreate () {
    this.$store.dispatch('auth/initClientId').then()
  },
  created () {
    // let code = 0
    // let code2 = 0

    // document.onkeydown = function (e) {
    //   console.log(e)
    //   let evn = e || event
    //   let key = evn.keyCode || evn.which || evn.charCode
    //   console.log(key)
    //   if (key === 17) {
    //     code2 = 2
    //   }
    //   if (key === 71) {
    //     code = 1
    //   }
    //   // Ctrol + C
    //   if (code === 1 && code2 === 2) {
    //     console.log('ok')
    //   }
    // }
  },

  mounted () {
    document.body.classList.add('bp')

    this.spinnerTimeoutId = setTimeout(
      () => {
        const spinner = document.getElementById('spinner')

        if (spinner) {
          spinner.remove()
        }
      },
      900
    )
    // this.performanceStatistics()
  },
  destroyed () {
    if (this.spinnerTimeoutId) {
      clearTimeout(this.spinnerTimeoutId)
    }
  }
}
</script>

<style lang="scss">
  body {
    color: #647279;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
  }

  @import "../core/styles/base.scss";
  @import "./assets/styles/style.scss";
  @import "./assets/styles/reset.scss";
</style>
