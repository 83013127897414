import BlankLayout from '@admin/components/BlankLayout'

const Home = r => require.ensure([], () => r(require('@admin/views/order/Index')))
const Setting = r => require.ensure([], () => r(require('@admin/views/order/setting')))
const WmHome = r => require.ensure([], () => r(require('@admin/views/order/wmIndex')))

const routes = [
  {
    path: '/order',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '订单管理',
        path: '/order'
      }
    },
    children: [
      {
        path: '',
        component: Home

      },
      {
        path: 'setting',
        component: Setting,
        meta: {
          breadcrumb: {
            title: '下单设置'
          }
        }
      },
      {
        path: 'wm',
        component: WmHome,
        meta: {
          breadcrumb: {
            title: '外卖订单列表'
          }
        }
      }
    ]

  }
]

export default routes
