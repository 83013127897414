import BlankLayout from '@admin/components/BlankLayout'

const OrderHome = r => require.ensure([], () => r(require('@admin/views/label/order/Index')))
const OrderCreate = r => require.ensure([], () => r(require('@admin/views/label/order/Create')))
const OrderEdit = r => require.ensure([], () => r(require('@admin/views/label/order/Edit')))

const Feedback = r => require.ensure([], () => r(require('@admin/views/label/feedback/Index')))
// const FeedbackCreate = r => require.ensure([], () => r(require('@admin/views/label/feedback/Create')))
// const FeedbackEdit = r => require.ensure([], () => r(require('@admin/views/label/feedback/Edit')))

const routes = [
  {
    path: '/label/order',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '备注标签',
        path: '/label/order'
      },
      requiresAuth: true
    },
    children: [{
      path: '',
      component: OrderHome
    },
    {
      path: 'create',
      component: OrderCreate,
      meta: {
        breadcrumb: {
          title: '创建标签'
        }
      }
    },
    {
      path: 'update',
      component: OrderEdit,
      meta: {
        breadcrumb: {
          title: '编辑标签'
        }
      }
    }
    ]
  },
  {
    path: '/label/feedback',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '反馈标签',
        path: '/label/feedback'
      },
      requiresAuth: true
    },
    children: [{
      path: '',
      component: Feedback
    }
      // {
      //   path: 'create',
      //   component: FeedbackCreate,
      //   meta: {
      //     breadcrumb: {
      //       title: '创建标签'
      //     }
      //   }
      // },
      // {
      //   path: 'update',
      //   component: FeedbackEdit,
      //   meta: {
      //     breadcrumb: {
      //       title: '编辑标签'
      //     }
      //   }
      // }

    ]
  }
]

export default routes
