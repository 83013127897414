import Vue from 'vue'
import VueRouter from 'vue-router'
import { routerHistory, writeHistory } from 'vue-router-back-button'

import userRoutes from './user'
import shopRoutes from './shop'
import adminRoutes from './admin'
import storeRoutes from './store'
import classifyRoutes from './classify'
import flavorRoutes from './flavor'
import settingRoutes from './setting'
import orderRoutes from './order'
import couponRoutes from './coupon'
import numberRoutes from './number'
import integralRoutes from './integral'
import moneyRoutes from './money'
import labelRoutes from './label'
import msgRoutes from './msg'
import reviewRoutes from './review'
import reportRoutes from './report'
import qrBarCodeRoutes from './qr-bar-code'
import officialRoutes from './official'
import posRoutes from './pos'
import shareRoutes from './share'
import homeRoutes from './home'

import siteIndex from '@admin/views/site/Index'
import siteLogin from '@admin/views/site/Login'
import notFound from '@admin/views/site/NotFound'

import defaultLayout from '@admin/components/DefaultLayout'

import Auth from '@admin/utils/auth'

Vue.use(VueRouter)
Vue.use(routerHistory)

const routes = [
  {
    path: '/',
    component: defaultLayout,
    children: [
      ...homeRoutes,
      ...userRoutes,
      ...shopRoutes,
      ...adminRoutes,
      ...storeRoutes,
      ...classifyRoutes,
      ...flavorRoutes,
      ...settingRoutes,
      ...orderRoutes,
      ...couponRoutes,
      ...numberRoutes,
      ...integralRoutes,
      ...moneyRoutes,
      ...labelRoutes,
      ...msgRoutes,
      ...reviewRoutes,
      ...reportRoutes,
      ...posRoutes,
      ...qrBarCodeRoutes,
      ...officialRoutes,
      ...shareRoutes,
      {
        path: 'home',
        component: siteIndex,
        alias: '',
        meta: {
          breadcrumb: {
            title: '首页'
          }
        }
      }
    ]
  },
  {
    path: '/login',
    component: siteLogin
  },
  {
    path: '*',
    component: notFound
  }
]

const router = new VueRouter({
  base: '/admin',
  mode: 'history',
  routes: routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return { selector: to.hash }
    }

    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.path !== '/login') {
    if (Auth.getAccessToken()) { // 如果本地存在 access_token，则继续导航
      next()
    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }
})

router.afterEach(writeHistory)

export default router
