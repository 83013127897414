import BlankLayout from '@admin/components/BlankLayout'

const Home = r => require.ensure([], () => (require('@admin/views/report/index')))
const View = r => require.ensure([], () => r(require('@admin/views/report/pc-view')))
// const Shop = r => require.ensure([], () => (require('@admin/views/report/shop')))

const routes = [
  {
    path: '/report',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '营业报表',
        path: '/report'
      }
    },
    children: [
      {
        path: '',
        component: Home,
        meta: {
          breadcrumb: {
            title: '数据概览'
          }
        }

      },
      {
        path: 'pc-view',
        component: View,
        meta: {
          breadcrumb: {
            title: '数据详情'
          }
        }
      }
    ]

  }
]

export default routes
