const CryptoJS = require('crypto-js') // 引用AES源码js

const AES_KEY = 'LlJI3NIMJ3GHSD80' // 16位
const AES_IV = 'ADC01PoIU3NMBV30' // 16位

// 加密
function Encrypt (plainText) {
  var encrypted = CryptoJS.AES.encrypt(plainText, CryptoJS.enc.Utf8.parse(AES_KEY), { iv: CryptoJS.enc.Utf8.parse(AES_IV) })
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}
// 解密
function Decrypt (ciphertext) {
  var decrypted = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(AES_KEY), { iv: CryptoJS.enc.Utf8.parse(AES_IV) })
  return decrypted.toString(CryptoJS.enc.Utf8)
}

function dateFtt (fmt, date) {
  var o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S': date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}

export default {
  Decrypt,
  Encrypt,
  dateFtt
}
