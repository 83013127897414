import crypto from './cryptojs'

const clientIdItemKey = 'client-id'
const accessTokenItemKey = 'ACCESS_TOKEN'

const Auth = {
  getHttpApiUrl () {
    let HTTP_URL = ''
    if (process.env.NODE_ENV !== 'production') {
      HTTP_URL = 'http://localhost:9527/admin/api/'
    } else {
      HTTP_URL = document.querySelector('meta[name="api-host"]').getAttribute('content')
    }
    return HTTP_URL
  },

  getHostUrl () {
    let HTTP_URL = ''
    if (process.env.NODE_ENV !== 'production') {
      HTTP_URL = 'http://localhost:9527/'
    } else {
      HTTP_URL = document.querySelector('meta[name="host"]').getAttribute('content')
    }
    return HTTP_URL
  },
  getClientId () {
    return window.localStorage.getItem(clientIdItemKey)
  },

  setClientId (clientId) {
    window.localStorage.setItem(clientIdItemKey, clientId)
  },

  getAccessToken () {
    let Token = ''
    const accessToken = window.localStorage.getItem(accessTokenItemKey)
    if (accessToken) {
      const _token = crypto.Decrypt(accessToken)
      const _tokens = _token.split(',')
      const time = crypto.dateFtt('yyyy-MM-dd hh:mm:ss', new Date())
      console.log('请求时间:', time)
      const Str = _tokens[0] + ',' + time
      Token = crypto.Encrypt(Str)
    } else {
      Token = accessToken
    }
    return Token
  },

  setAccessToken (accessToken) {
    if (accessToken == null) {
      window.localStorage.removeItem(accessTokenItemKey)
    } else {
      window.localStorage.setItem(accessTokenItemKey, accessToken)
    }
  }
}

export default Auth
