import BlankLayout from '@admin/components/BlankLayout'

const Home = r => require.ensure([], () => r(require('@admin/views/coupon/Index')))
const Create = r => require.ensure([], () => r(require('@admin/views/coupon/Create')))
const Edit = r => require.ensure([], () => r(require('@admin/views/coupon/Edit')))

const sHome = r => require.ensure([], () => r(require('@admin/views/coupon/Send/Index')))
const sCreate = r => require.ensure([], () => r(require('@admin/views/coupon/Send/Create')))
const sEdit = r => require.ensure([], () => r(require('@admin/views/coupon/Send/Edit')))

const routes = [
  {
    path: '/coupon',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '优惠劵',
        path: '/coupon'
      }
    },
    children: [
      {
        path: '',
        component: Home
      },
      {
        path: 'create',
        component: Create,
        meta: {
          breadcrumb: {
            title: '新建'
          }
        }
      },
      {
        path: 'update',
        component: Edit,
        meta: {
          breadcrumb: {
            title: '编辑'
          }
        }
      }

    ]

  },
  {
    path: '/send-coupon',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '新发优惠劵',
        path: '/send-coupon'
      }
    },
    children: [
      {
        path: '',
        component: sHome
      },
      {
        path: 'create',
        component: sCreate,
        meta: {
          breadcrumb: {
            title: '新建'
          }
        }
      },
      {
        path: 'update',
        component: sEdit,
        meta: {
          breadcrumb: {
            title: '编辑'
          }
        }
      }
    ]
  }

]

export default routes
