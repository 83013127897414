import BlankLayout from '@admin/components/BlankLayout'

const shareHome = r => require.ensure([], () => r(require('@admin/views/share/Index')))

const routes = [
  {
    path: '/share',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '邀请有礼',
        path: '/share'
      },
      requiresAuth: true
    },
    children: [{
      path: '',
      component: shareHome
    }
    ]
  }
]

export default routes
