<template>
   <div class="box">
        <el-container>
            <el-container>
                <el-header>
                <el-menu
                    mode="horizontal"
                    :collapse-transition="false"
                    :router="true"
                    :default-active="getRouteActive"
                >
                <el-menu-item
                    v-for="(menu,index) in getMenus"
                    :index="menu.id"
                    :key="index"
                    :route='menu.url'
                    @click="checkoutRouter(menu)"
                >{{menu.label}}</el-menu-item>
                </el-menu>
                </el-header>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>

export default {
  name: '',
  props: [''],
  data () {
    return {
      getRouteActive: '0',
      getMenus: [
        {
          id: '0',
          label: '消息通知设置',
          url: '/msg'
        },

        {
          id: '1',
          label: '消息通知统计',
          url: '/msg/activity'
        },
        {
          id: '2',
          label: '营销活动通知统计',
          url: '/msg/coupon'
        }
      ]
    }
  },
  async created () {
    if (this.$router.currentRoute.path === '/msg/activity') {
      this.getRouteActive = '1'
    } else if (this.$router.currentRoute.path === '/msg/coupon') {
      this.getRouteActive = '2'
    } else {
      this.getRouteActive = '0'
    }
  },
  methods: {
    checkoutRouter (e) {
      this.getRouteActive = e.id
    }
  }
}

</script>
<style lang='' scoped>

</style>
