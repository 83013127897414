import Layout from '@admin/views/msg/components/_Layout'

import BlankLayout from '@admin/components/BlankLayout'

const Home = r => require.ensure([], () => r(require('@admin/views/msg/Index')))
const Create = r => require.ensure([], () => r(require('@admin/views/msg/Create')))
const Edit = r => require.ensure([], () => r(require('@admin/views/msg/Edit')))
const Activity = r => require.ensure([], () => r(require('@admin/views/msg/Activity')))
const Coupon = r => require.ensure([], () => r(require('@admin/views/msg/Coupon')))

const routes = [
  {
    path: '/msg',
    component: Layout,
    meta: {
      breadcrumb: {
        title: '通知管理',
        path: '/msg'
      }
    },

    children: [
      {
        path: '',
        component: Home,
        meta: {
          breadcrumb: {
            title: '消息通知设置'
          }
        }
      },
      {
        path: 'activity',
        component: Activity,
        meta: {
          breadcrumb: {
            title: '消息通知'
          }
        }
      },
      {
        path: 'coupon',
        component: Coupon,
        meta: {
          breadcrumb: {
            title: '营销活动通知'
          }
        }
      }
    ]
  },
  {
    path: '/msg-temp',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '通知管理',
        path: '/msg'
      }
    },

    children: [
      {
        path: 'create',
        component: Create,
        meta: {
          breadcrumb: {
            title: '新建'
          }
        }
      },
      {
        path: 'update',
        component: Edit,
        meta: {
          breadcrumb: {
            title: '编辑'
          }
        }
      }
    ]
  }
]

export default routes
