import BlankLayout from '@admin/components/BlankLayout'

const ShopHome = r => require.ensure([], () => r(require('@admin/views/shop/Index')))
const ShopEdit = r => require.ensure([], () => r(require('@admin/views/shop/Edit')))

const TempHome = r => require.ensure([], () => r(require('@admin/views/shop-temp/Index')))
const TempCreate = r => require.ensure([], () => r(require('@admin/views/shop-temp/Create')))
const TempEdit = r => require.ensure([], () => r(require('@admin/views/shop-temp/Edit')))
const TempPeiZhi = r => require.ensure([], () => r(require('@admin/views/shop-temp/PeiZhi')))

const routes = [
  {
    path: '/shop',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '商品列表',
        path: '/shop'
      }
    },
    children: [
      {
        path: '',
        component: ShopHome
      },
      {
        path: 'update',
        component: ShopEdit,
        meta: {
          breadcrumb: {
            title: '商家编辑'
          }
        }
      }

    ]
  },
  {
    path: '/shop-temp',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '素材列表',
        path: '/shop-temp'
      }
    },
    children: [
      {
        path: '',
        component: TempHome
      },
      {
        path: 'create',
        component: TempCreate,
        meta: {
          breadcrumb: {
            title: '素材创建'
          }
        }
      },
      {
        path: 'update',
        component: TempEdit,
        meta: {
          breadcrumb: {
            title: '素材编辑'
          }
        }
      },
      {
        path: 'pei-zhi',
        component: TempPeiZhi,
        meta: {
          breadcrumb: {
            title: '素材配置'
          }
        }
      }

    ]
  }
]

export default routes
