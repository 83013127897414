import BlankLayout from '@admin/components/BlankLayout'

const Review = r => require.ensure([], () => r(require('@admin/views/review/Index')))

const ReviewCoupon = r => require.ensure([], () => r(require('@admin/views/review/coupon')))

const routes = [
  {
    path: '/review',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '点评列表',
        path: '/review'
      },
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: Review
      },
      {
        path: 'coupon',
        component: ReviewCoupon,
        meta: {
          breadcrumb: {
            title: '点评赠券'
          }
        }
      }
    ]
  }
]

export default routes
