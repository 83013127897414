import BlankLayout from '@admin/components/BlankLayout'

const UserHome = r => require.ensure([], () => r(require('@admin/views/user/Index')))
// const UserCreate = r => require.ensure([], () => r(require('@admin/views/user/Create')))
const UserChange = r => require.ensure([], () => r(require('@admin/views/user/Change')))

const LevelHome = r => require.ensure([], () => r(require('@admin/views/user/level/Index')))
const LevelCreate = r => require.ensure([], () => r(require('@admin/views/user/level/Create')))
const LevelEdit = r => require.ensure([], () => r(require('@admin/views/user/level/Edit')))

const Export = r => require.ensure([], () => r(require('@admin/views/user/backup/index')))

const routes = [
  {
    path: '/user',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '会员',
        path: '/user'
      },
      requiresAuth: true
    },
    children: [{
      path: '',
      component: UserHome
    },
    // {
    //   path: 'create',
    //   component: UserCreate,
    //   meta: {
    //     breadcrumb: {
    //       title: '新建'
    //     }
    //   }
    // },
    {
      path: 'export',
      component: Export,
      meta: {
        breadcrumb: {
          title: '导入用户'
        }
      }
    },
    {
      path: 'change',
      component: UserChange,
      meta: {
        breadcrumb: {
          title: '调整数据'
        }
      }
    }
    ]
  },
  {
    path: 'level',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '等级管理',
        path: '/level'
      },
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: LevelHome
      },
      {
        path: 'create',
        component: LevelCreate,
        meta: {
          breadcrumb: {
            title: '新建等级管理'
          }
        }
      },
      {
        path: 'update',
        component: LevelEdit,
        meta: {
          breadcrumb: {
            title: '新建等级管理'
          }
        }
      }
    ]
  }
]

export default routes
