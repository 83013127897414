import BlankLayout from '@admin/components/BlankLayout'

const officialHome = r => require.ensure([], () => r(require('@admin/views/official/Index')))
const officialCreate = r => require.ensure([], () => r(require('@admin/views/official/Create')))
const officialEdit = r => require.ensure([], () => r(require('@admin/views/official/Edit')))

const routes = [
  {
    path: '/official',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: 'Banner管理',
        path: '/official'
      },
      requiresAuth: true
    },
    children: [{
      path: '',
      component: officialHome
    },
    {
      path: 'create',
      component: officialCreate,
      meta: {
        breadcrumb: {
          title: '创建Banner'
        }
      }
    },
    {
      path: 'update',
      component: officialEdit,
      meta: {
        breadcrumb: {
          title: '编辑Banner'
        }
      }
    }
    ]
  }
]

export default routes
