import BlankLayout from '@admin/components/BlankLayout'

const RuleHome = r => require.ensure([], () => r(require('@admin/views/integral/rule/Index')))
const ShopHome = r => require.ensure([], () => r(require('@admin/views/integral/shop/Index')))
const ShopCreate = r => require.ensure([], () => r(require('@admin/views/integral/shop/Create')))
const ShopEdit = r => require.ensure([], () => r(require('@admin/views/integral/shop/Edit')))

//
const IntegralLog = r => require.ensure([], () => r(require('@admin/views/integral/log-all/Integral')))
const MoneyLog = r => require.ensure([], () => r(require('@admin/views/integral/log-all/Money')))

const routes = [
  {
    path: '/integral',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '积分管理',
        path: '/integral/shop'
      }
    },

    children: [
      {
        path: 'rule',
        component: RuleHome,
        meta: {
          breadcrumb: {
            title: '规则'
          }
        }
      },
      {
        path: 'shop',
        component: ShopHome,
        meta: {
          breadcrumb: {
            title: '列表'
          }
        }
      },
      {
        path: 'shop/create',
        component: ShopCreate,
        meta: {
          breadcrumb: {
            title: '新建商品'
          }
        }
      },
      {
        path: 'shop/update',
        component: ShopEdit,
        meta: {
          breadcrumb: {
            title: '编辑商品'
          }
        }
      },
      {
        path: 'integral-log',
        component: IntegralLog,
        meta: {
          breadcrumb: {
            title: '积分记录'
          }
        }
      },
      {
        path: 'money-log',
        component: MoneyLog,
        meta: {
          breadcrumb: {
            title: '储值记录'
          }
        }
      }
    ]
  }
]

export default routes
