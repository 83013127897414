import SettingLayout from '@admin/views/setting/components/_Layout.vue'

const Home = r => require.ensure([], () => r(require('@admin/views/setting/Index')))
const Mini = r => require.ensure([], () => r(require('@admin/views/setting/miniProgram')))
const Sms = r => require.ensure([], () => r(require('@admin/views/setting/sms')))
const Photo = r => require.ensure([], () => r(require('@admin/views/setting/photo')))

const Pay = r => require.ensure([], () => r(require('@admin/views/setting/pay')))
const Feedback = r => require.ensure([], () => r(require('@admin/views/feedback/Index')))

const WebSite = r => require.ensure([], () => r(require('@admin/views/setting/webSite')))
const Refund = r => require.ensure([], () => r(require('@admin/views/setting/refund')))

const GZH = r => require.ensure([], () => r(require('@admin/views/setting/official')))
const Jump = r => require.ensure([], () => r(require('@admin/views/setting/jump')))

const routes = [
  {
    path: '/setting',
    component: SettingLayout,
    meta: {
      breadcrumb: {
        title: '系统设置',
        path: '/setting'
      }
    },
    children: [
      {
        path: '',
        component: WebSite

      },
      {
        path: 'index',
        component: Home
      },
      {
        path: 'mini-program',
        component: Mini,
        meta: {
          breadcrumb: {
            title: '小程序设置',
            path: '/setting/mini-program'
          }
        }
      },
      {
        path: 'sms',
        component: Sms,
        meta: {
          breadcrumb: {
            title: '短信设置',
            path: '/setting/sms'
          }
        }
      },
      {
        path: 'jump',
        component: Jump,
        meta: {
          breadcrumb: {
            title: '小程序跳转设置',
            path: '/setting/jump'
          }
        }
      },
      {
        path: 'official',
        component: GZH,
        meta: {
          breadcrumb: {
            title: '公众号设置',
            path: '/setting/official'
          }
        }
      },
      {
        path: 'pay',
        component: Pay,
        meta: {
          breadcrumb: {
            title: '支付设置',
            path: '/setting/pay'
          }
        }
      },
      {
        path: 'web-site',
        component: WebSite,
        meta: {
          breadcrumb: {
            title: '网站设置',
            path: '/setting/web-site'
          }
        }
      },
      {
        path: 'refund',
        component: Refund,
        meta: {
          breadcrumb: {
            title: '退款设置',
            path: '/setting/refund'
          }
        }
      }
    ]

  },

  {
    path: '/setting/mini-photo',
    component: Photo,
    meta: {
      breadcrumb: {
        title: '小程序图片',
        path: '/setting/photo'
      }
    }
  },
  // 反馈列表
  {
    path: '/feedback',
    component: Feedback,
    meta: {
      breadcrumb: {
        title: '反馈列表',
        path: '/feedback'
      }
    }
  }
]

export default routes
