<template>
    <div class="box">
        <el-container>
            <el-aside style="padding-right:16px;width:200px;border-right:1px solid #f4f4f4;">
                <el-menu
                    :collapse-transition="false"
                    :router="true"
                    :default-active="getRouteActive"
                >
                <el-menu-item
                    v-for="(menu,index) in getMenus"
                    :index="menu.id"
                    :key="index"
                    :route='menu.url'
                >{{menu.label}}</el-menu-item>
                </el-menu>
            </el-aside>
            <el-container>
                <!-- <el-header></el-header> -->
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data () {
    return {
      getRouteActive: '0',
      getMenus: [
        {
          id: '0',
          label: '网站设置',
          url: '/setting/web-site'
        },
        {
          id: '1',
          label: '系统开关设置',
          url: '/setting/Index'
        },

        {
          id: '2',
          label: '小程序配置',
          url: '/setting/mini-program'
        },
        {
          id: '3',
          label: '公众号设置',
          url: '/setting/official'
        },
        {
          id: '4',
          label: '支付设置',
          url: '/setting/pay'
        },
        {
          id: '5',
          label: '退款设置',
          url: '/setting/refund'
        },
        {
          id: '6',
          label: '小程序跳转设置',
          url: '/setting/jump'
        }
      ]
    }
  },
  created () {
    const url = this.$router.currentRoute.path
    this.getMenus.forEach(element => {
      if (element.url === url) {
        this.getRouteActive = element.id
      }
    })
  }
}
</script>
<style lang="scss">
.setting-box{
  .el-form{
    // padding-right: 180px;
  }
}
</style>
