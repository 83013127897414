import BlankLayout from '@admin/components/BlankLayout'

const Index = r => require.ensure([], () => r(require('@admin/views/qr-bar-code/Index')))
const Create = r => require.ensure([], () => r(require('@admin/views/qr-bar-code/Create')))

const routes = [{
  path: '/code',
  component: BlankLayout,
  meta: {
    breadcrumb: {
      title: '商品条码',
      path: '/code'
    }
  },
  children: [{
    path: '',
    component: Index
  },
  {
    path: 'create',
    component: Create,
    meta: {
      breadcrumb: {
        title: '新建'
      }
    }
  }
  ]
}]

export default routes
