import BlankLayout from '@admin/components/BlankLayout'

const Home = r => require.ensure([], () => r(require('@admin/views/number/Index')))
const Create = r => require.ensure([], () => r(require('@admin/views/number/Create')))

const routes = [
  {
    path: '/number',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '桌号管理',
        path: '/number'
      }
    },

    children: [
      {
        path: '',
        component: Home

      },
      {
        path: 'create',
        component: Create,
        meta: {
          breadcrumb: {
            title: '新建'
          }
        }
      }
    ]
  }
]

export default routes
