import BlankLayout from '@admin/components/BlankLayout'

const AdminHome = r => require.ensure([], () => r(require('@admin/views/admin/Index')))
const AdminCreate = r => require.ensure([], () => r(require('@admin/views/admin/Create')))
const AdminEdit = r => require.ensure([], () => r(require('@admin/views/admin/Edit')))

const LogHome = r => require.ensure([], () => r(require('@admin/views/log/Index')))

const RoleHome = r => require.ensure([], () => r(require('@admin/views/role/Index')))
const RoleCreate = r => require.ensure([], () => r(require('@admin/views/role/Create')))
const RoleEdit = r => require.ensure([], () => r(require('@admin/views/role/Edit')))

const routes = [
  {
    path: '/admin',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '管理员列表',
        path: '/admin'
      },
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: AdminHome
      },
      {
        path: 'create',
        component: AdminCreate,
        meta: {
          breadcrumb: {
            title: '新建'
          }
        }
      },
      {
        path: 'update',
        component: AdminEdit,
        meta: {
          breadcrumb: {
            title: '编辑'
          }
        }
      },
      {
        path: 'log',
        component: LogHome,
        meta: {
          breadcrumb: {
            title: '日志'
          }
        }
      }
    ]
  },
  {
    path: '/role',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '角色管理',
        path: '/role'
      }
    },
    children: [
      {
        path: '',
        component: RoleHome
      },
      {
        path: 'create',
        component: RoleCreate,
        meta: {
          breadcrumb: {
            title: '新建'
          }
        }
      },
      {
        path: 'update',
        component: RoleEdit,
        meta: {
          breadcrumb: {
            title: '编辑'
          }
        }
      }
    ]
  }
]

export default routes
