import BlankLayout from '@admin/components/BlankLayout'

const routes = [
  {
    path: '/store',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '店铺列表',
        path: '/store'
      }
    },
    children: [
      {
        path: '',
        component: () => import('@admin/views/store/Index')

      },
      {
        path: 'create',
        component: () => import('@admin/views/store/Create'),
        meta: {
          breadcrumb: {
            title: '新建'
          }
        }
      },
      {
        path: 'update',
        component: () => import('@admin/views/store/Edit'),
        meta: {
          breadcrumb: {
            title: '编辑'
          }
        }
      },
      {
        path: 'setting',
        component: () => import('@admin/views/store/dining'),
        meta: {
          breadcrumb: {
            title: '就餐设置'
          }
        }
      },
      {
        path: 'set-edit',
        component: () => import('@admin/views/store/diningEdit'),
        meta: {
          breadcrumb: {
            title: '就餐设置'
          }
        }
      },
      {
        path: 'wm',
        component: () => import('@admin/views/store/Waimai'),
        meta: {
          breadcrumb: {
            title: '就餐设置'
          }
        }
      }
    ]
  }
]

export default routes
