<template>
  <div class="box">
    <p>欢迎光临。</p>
    <!-- <prom></prom> -->
    <!-- <web></web> -->
  </div>
</template>

<script>
// import prom from './components/_prom'
// import web from './components/web'
export default {
  metaInfo: {
    title: '首页'
  },
  // components: { prom, web },
  data () {
    return {
      time: new Date()
    }
  }
}
</script>
<style lang="scss">
.i {
    margin-bottom: 10px;
    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}
</style>
