import BlankLayout from '@admin/components/BlankLayout'

const Home = r => require.ensure([], () => r(require('@admin/views/pos//Index')))
const Create = r => require.ensure([], () => r(require('@admin/views/pos/Create')))
const Edit = r => require.ensure([], () => r(require('@admin/views/pos/Edit')))
const Setting = r => require.ensure([], () => r(require('@admin/views/pos/Setting')))
const Label = r => require.ensure([], () => r(require('@admin/views/pos/Label')))

const routes = [
  {
    path: '/pos',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: 'POS机',
        path: '/pos'
      },
      requiresAuth: true
    },
    children: [{
      path: '',
      component: Home
    },
    {
      path: 'create',
      component: Create,
      meta: {
        breadcrumb: {
          title: '新建POS'
        }
      }
    },
    {
      path: 'update',
      component: Edit,
      meta: {
        breadcrumb: {
          title: '编辑POS'
        }
      }
    },
    {
      path: 'setting',
      component: Setting,
      meta: {
        breadcrumb: {
          title: 'POS信息'
        }
      }
    },
    {
      path: 'label',
      component: Label,
      meta: {
        breadcrumb: {
          title: '原因'
        }
      }
    }
    ]
  }
]

export default routes
