import BlankLayout from '@admin/components/BlankLayout'

const Home = r => require.ensure([], () => r(require('@admin/views/site/Index')))
const Log = r => require.ensure([], () => r(require('@admin/views/site/components/_LogModel')))
const Sql = r => require.ensure([], () => r(require('@admin/views/site/components/_SqlModel')))

const routes = [
  {
    path: '/home',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '首页',
        path: '/home'
      }
    },

    children: [
      {
        path: '',
        component: Home,
        meta: {
          breadcrumb: {
            title: ''
          }
        }
      },
      {
        path: 'log',
        component: Log,
        meta: {
          breadcrumb: {
            title: '日志'
          }
        }
      },
      {
        path: 'sql',
        component: Sql,
        meta: {
          breadcrumb: {
            title: '数据库'
          }
        }
      }
    ]
  }
]

export default routes
