import BlankLayout from '@admin/components/BlankLayout'

const routes = [
  {
    path: '/flavor',
    component: BlankLayout,
    meta: {
      breadcrumb: {
        title: '商品属性',
        path: '/flavor'
      }
    },
    children: [
      {
        path: '',
        component: () => import('@admin/views/flavor/Index')

      },
      {
        path: 'create',
        component: () => import('@admin/views/flavor/Create'),
        meta: {
          breadcrumb: {
            title: '新建'
          }
        }
      },
      {
        path: 'update',
        component: () => import('@admin/views/flavor/Edit'),
        meta: {
          breadcrumb: {
            title: '编辑'
          }
        }
      }
    ]
  }
]

export default routes
