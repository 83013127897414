import Vue from 'vue'

const AuthService = {
  login (credentials) {
    return Vue.http.post('site/login', credentials)
  },
  logout () {
    return Vue.http.post('site/logout')
  },
  authentication () {
    return Vue.http.get('auth/authentication')
  }
}

export default AuthService
