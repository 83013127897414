import BlankLayout from '@admin/components/BlankLayout'

const Home = r => require.ensure([], () => r(require('@admin/views/money/Index')))
const Create = r => require.ensure([], () => r(require('@admin/views/money/Create')))
const Change = r => require.ensure([], () => r(require('@admin/views/money/Edit')))

const Free = r => require.ensure([], () => r(require('@admin/views/money/free/Index')))
const FreeCreate = r => require.ensure([], () => r(require('@admin/views/money/free/Create')))
const FreeEdit = r => require.ensure([], () => r(require('@admin/views/money/free/Edit')))

const Explain = r => require.ensure([], () => r(require('@admin/views/money/Explain')))

const routes = [{
  path: '/money',
  component: BlankLayout,
  meta: {
    breadcrumb: {
      title: '储值管理',
      path: '/money'
    },
    requiresAuth: true
  },
  children: [{
    path: '',
    component: Home
  },
  {
    path: 'create',
    component: Create,
    meta: {
      breadcrumb: {
        title: '创建储值数据'
      }
    }
  },
  {
    path: 'update',
    component: Change,
    meta: {
      breadcrumb: {
        title: '编辑储值数据'
      }
    }
  },
  {
    path: 'explain',
    component: Explain,
    meta: {
      breadcrumb: {
        title: '储值说明'
      }
    }
  }
  ]
},
{
  path: '/free',
  component: BlankLayout,
  meta: {
    breadcrumb: {
      title: '免单用户',
      path: '/free'
    },
    requiresAuth: true
  },
  children: [{
    path: '',
    component: Free
  },
  {
    path: 'create',
    component: FreeCreate,
    meta: {
      breadcrumb: {
        title: '创建免单用户'
      }
    }
  },
  {
    path: 'update',
    component: FreeEdit,
    meta: {
      breadcrumb: {
        title: '编辑免单用户'
      }
    }
  }

  ]
}
]

export default routes
